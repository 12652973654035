@import '../../styles/colors.scss';

.googleAdHolder {
  display: flex;
  justify-content: center;

  .googleAd {
    iframe { // iframe is created by google ads
      border: 1px solid $border-grey-color !important;
    }
  }
}
